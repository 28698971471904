<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-text>
        <div>{{ $t('aboutThisTool') }}</div>
        <p class="display-1 text--primary">{{ $t('Disclaimer') }}</p>
        <div class="text--primary">
          {{ $t('aboutThisToolContent') }}
        </div>
        <br />
        <!-- <p class="title text--primary">{{ $t('disclaimerSourceInfo') }}</p>
        <div class="text--primary">
          {{ $t('disclaimerSourceInfoText') }}
          <a href="https://github.com/duckduckgo/tracker-radar">tracker-radar</a>
        </div> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AboutThisTool',
  data: () => ({}),
  metaInfo: {
    title: 'Free Cookie Scanner - about this page.',
    decription: 'What is cookie-scanner about and how does it work?',
  },
};
</script>
